html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
body {
  overflow: auto;
  color: white;
}
* {
  font-family: monospace;
}

#root {
  /* background-image: url(https://cdn.midjourney.com/f4d40d39-40e1-4037-b3fa-e682861d5b09/grid_0.png); */
  /* background-image: url(https://media.discordapp.net/attachments/997271858925809714/1055996061019279390/MaxSwell_Beggar_cyberpunk_glow_f6092500-736b-441c-ad34-4a1426ff9a5f.png); */
  background-image: url(https://cdn.midjourney.com/35c18ec9-03cf-4714-bd73-b6fc75acdae3/grid_0.png);
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height: 100vh;
}

#obfuscator {
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.App {
  position: relative;
  z-index: 1;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content {
  margin: 0 auto 0;
  width: 90%;
  max-width: 1200px;
  padding: 2em;
}

.alertContainer {
  padding: 3em 0;
  width: 80%;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.logoWojak {
  width: 192px;
  height: 192px;
  margin-bottom: -0.8em;
}

.metamaskLogo {
  width: 192px;
  height: 192px;
  margin-top: 2em;
}

.title {
  margin-bottom: 1em;
}
/* .title:nth-of-type(1) {
  margin-top: 0;
} */

.sectionSpacer {
  margin-top: 6em;
  margin-bottom: 6em;
}

.btnAction.btnParticipate {
  opacity: 1;
}
.btnAction.btnDrawLottery {
  background-color: rgba(231, 193, 247, 0.6) !important;
}

.btnRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

/** ANT */
.ant-modal-content {
  background-color: #141414 !important;
  color: white;
}
.ant-modal-content .ant-modal-confirm-title,
.ant-modal-content .ant-modal-confirm-content {
  color: white !important;
}

.ant-alert {
  color: white;
  background-color: rgba(58, 38, 95, 0.33) !important;
}
.ant-btn {
  background-color: rgb(194 172 234 / 13%) !important;
  color: white;
}

.ant-input {
  background-color: rgb(194 172 234 / 13%) !important;
  color: white;
  border-color: rgba(255, 255, 255, 0.473);
}
