.root {
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    gap: 20px;

    .card {
      height: 140px;
      margin-bottom: 1em;
      background-color: rgba(64, 61, 68, 0.33);
      border-color: rgba(255, 255, 255, 0.13);
      opacity: 0.9;
      position: relative;

      &:nth-child(even) {
        background-image: url("https://cdn.midjourney.com/0a652330-2061-4a04-9bae-96ff6bb1e652/grid_0.png");
      }
      &:nth-child(odd) {
        background-image: url("https://cdn.discordapp.com/attachments/997271858925809714/1056354821860495481/MaxSwell_cyberpunk_glow_46a7134a-4361-4dd4-88d5-11a985f560ff.png");
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: 130%;

      :global(.ant-card-body) {
        padding: 12px 12px 12px 16px;
        display: flex;
        align-items: center;
      }

      color: white;

      .label {
        margin: 0;
        // margin-left: 0.5em;
        background-color: rgba(0, 0, 0, 0.65);
        border-radius: 8px;
        min-width: 30px;
        text-align: center;
        position: absolute;
        left: 12px;
        bottom: 12px;
      }

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
